import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';

import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import Label from '../../../components/label';
import { fCurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function AssetTransferItemsTableRow({ selected, record, handleSelectClick }) {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleSelectClick} />
        </TableCell>

        <TableCell align="left">{record?.asset?.name}</TableCell>
        <TableCell align="left">{record?.asset?.asset_class}</TableCell>
        <TableCell align="left">{record?.asset?.barcode_number}</TableCell>
        <TableCell align="left">{record?.asset?.serial_no}</TableCell>
        <TableCell align="left">{fCurrency(record?.asset?.cost)}</TableCell>
        <TableCell align="left">{record?.asset?.location?.name}</TableCell>
        <TableCell align="left">{record?.asset?.assigned_to?.user?.display_name}</TableCell>

        <TableCell align="left">
          <Label color={(record?.asset?.status === 'PENDING' && 'error') || 'success'}>
            {sentenceCase(record?.asset?.status)}
          </Label>
        </TableCell>
        {/* <TableCell>
          <Button size="small" variant="contained" onClick={() => navigate(`/dashboard/assets/${record?.asset?.id}`)}>
            View
          </Button>
        </TableCell> */}
      </TableRow>
    </>
  );
}

AssetTransferItemsTableRow.propTypes = {
  record: PropTypes.object,
  handleSelectClick: PropTypes.func,
  selected: PropTypes.any,
};
