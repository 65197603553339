import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Stack, Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';

// import { getBranchManagers } from '../../users/store/userActions';
import { transferAsset } from '../store/assetActions';
import { getAuditBranches } from '../../branches/store/branchActions';

const ASSET_CONDITIONS = [
  {
    id: 1,
    label: 'Excellent',
    value: 'EXCELLENT',
  },
  {
    id: 2,
    label: 'Broken',
    value: 'BROKEN',
  },
];
NewTransfer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  assetIds: PropTypes.array,
};

export default function NewTransfer({ open, handleClose, assetIds }) {
  const dispatch = useDispatch();
  // const { auditUsers } = useSelector((state) => state.userReducer);
  const { auditBranches } = useSelector((state) => state.branchReducer);

  const NewTransferSchema = Yup.object().shape({});

  const defaultValues = {
    assetIds,
    branchId: '',
    condition: '',
    description: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewTransferSchema),
    defaultValues,
  });

  const {
    // reset,
    watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  console.log('VALUES:', values);

  const onSubmit = async (data) => {
    const payload = {
      branch_id: data.branchId,
      asset_ids: assetIds,
      condition: data.condition,
      description: data.description,
    };
    dispatch(transferAsset(payload));
    handleClose();
  };
  useEffect(() => {
    // dispatch(getBranchManagers({}));
    dispatch(getAuditBranches({}));
  }, [dispatch]);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Asset transfer</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            <RHFSelect
              fullWidth
              name="branchId"
              label="Select Branch"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {auditBranches?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option?.name}({option?.location_code})
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect
              fullWidth
              name="condition"
              label="Condition at Checkout"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {ASSET_CONDITIONS?.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFTextField name="description" label="Description" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            variant="contained"
            loading={isSubmitting}
            startIcon={<CheckCircleOutlineOutlinedIcon />}
          >
            Transfer
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
