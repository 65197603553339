import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Card, Grid, Stack, Container, CardHeader, CardContent, TextField } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { Page } from '../../../components';
import { getAuditBranches } from '../../branches/store/branchActions';
import { getAuditCategories } from '../../categories/store/categoryActions';
import { getAuditSuppliers } from '../../suppliers/store/supplierActions';
import { createAsset } from '../store/assetActions';
import { getCategorySubCategories } from '../../subcategories/store/subCategoryActions';
import { fDateYMD } from '../../../utils/formatTime';

const ASSET_CONDITIONS = [
  {
    id: 1,
    label: 'Excellent',
    value: 'EXCELLENT',
  },
  {
    id: 2,
    label: 'Broken',
    value: 'BROKEN',
  },
];

NewAssetForm.propTypes = {
  isEdit: PropTypes.bool,
  fixedAsset: PropTypes.object,
};

export default function NewAssetForm({ isEdit, fixedAsset }) {
  const dispatch = useDispatch();
  const { auditBranches } = useSelector((state) => state.branchReducer);
  const { auditCategories } = useSelector((state) => state.categoryReducer);
  const { auditSubCategories } = useSelector((state) => state.subCategoryReducer);
  const { auditSuppliers } = useSelector((state) => state.supplierReducer);

  const [purchaseDate, setPurchaseDate] = useState(new Date());
  const [categoryId, setCategoryId] = useState(null);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const NewAssetSchema = Yup.object().shape({
    preTag: Yup.string().required('Pretag is required'),
    name: Yup.string().required('Asset Name is required'),
    serialNumber: Yup.string(),
    purchaseCost: Yup.number().required('Asset cost is required'),
    purchaseDate: Yup.string().required('Purchase Date is required'),
    invoiceNumber: Yup.string(),
    grnNumber: Yup.string(),
    watts: Yup.number(),
    supplier: Yup.string().required('Supplie is required'),
    category: Yup.string().required('Category is required'),
    subCategory: Yup.string().required('SubCategory is required'),
    branch: Yup.string().required('Branch is required'),
    condition: Yup.string().required('Condition is required'),
  });

  const defaultValues = {
    preTag: fixedAsset?.first_name || '',
    name: fixedAsset?.first_name || '',
    serialNumber: fixedAsset?.first_name || '',
    purchaseCost: fixedAsset?.first_name || '',
    purchaseDate: fixedAsset?.first_name || purchaseDate || '',
    invoiceNumber: fixedAsset?.first_name || '',
    grnNumber: fixedAsset?.first_name || '',
    watts: fixedAsset?.first_name || 0,
    supplier: fixedAsset?.first_name || '',
    category: fixedAsset?.first_name || '',
    subCategory: fixedAsset?.first_name || '',
    branch: fixedAsset?.first_name || '',
    condition: fixedAsset?.first_name || '',
    assetPhoto: fixedAsset?.first_name || '',
  };

  const methods = useForm({
    resolver: yupResolver(NewAssetSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  console.log('VALUES:', values);

  useEffect(() => {
    dispatch(getAuditSuppliers({}));
    dispatch(getAuditBranches({}));
    dispatch(getAuditCategories({}));
    if (isEdit && fixedAsset) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, fixedAsset]);

  useEffect(() => {
    if (categoryId) {
      dispatch(getCategorySubCategories(categoryId));
    }
  }, [categoryId, dispatch]);

  const handleSelectedCategory = (category) => {
    console.log('CAT:', category);
    setCategoryId(category);
    setValue('category', category);
  };
  const onSubmit = async (data) => {
    const payload = {
      pretag: data.preTag,
      name: data.name,
      serial_no: data.serialNumber,
      cost: data.purchaseCost,
      purchase_date: fDateYMD(purchaseDate),
      invoice_number: data.invoiceNumber,
      grn_number: data.grnNumber,
      watts: data.watts,
      supplier_id: data.supplier,
      asset_class_id: data.category,
      sub_class_id: data.subCategory,
      location_id: data.branch,
      condition: data.condition,
      asset_photo: data.assetPhoto,
    };
    console.log('PAYLOAD:', payload);
    try {
      dispatch(createAsset(payload));
      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      setTimeout(() => {
        navigate('/dashboard/assets');
      }, 2000); // 2000 mil
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Page title="New Asset">
      <Container maxWidth="xl">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ p: 3 }}>
                <CardHeader title={'Create asset'} subheader={''} />
                <CardContent>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <RHFTextField type='number' name="preTag" label={<span>PreTag<span style={{ color: 'red' }}> *</span></span>} />
                    <RHFTextField name="name" label={<span>Name<span style={{ color: 'red' }}> *</span></span>} />
                    <RHFTextField name="serialNumber" label="Serial Number" />
                    <RHFTextField type="number" name="purchaseCost"  label={<span>Purchase Cost<span style={{ color: 'red' }}> *</span></span>} />
                    <DatePicker
                      label={<span>Purchase Date<span style={{ color: 'red' }}> *</span></span>}
                      value={purchaseDate}
                      onChange={(newValue) => setPurchaseDate(newValue)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <RHFTextField name="invoiceNumber" label="Invoice Number" />
                    <RHFTextField name="grnNumber" label="GRN Number" />
                    <RHFTextField type="number" name="watts" label="Watts" />
                    <RHFSelect name="supplier" label={<span>Supplier<span style={{ color: 'red' }}> *</span></span>} placeholder="Supplier">
                      <option value="" />
                      {auditSuppliers?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.business_name}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFSelect
                      name="category"
                      label={<span>Category<span style={{ color: 'red' }}> *</span></span>}
                      placeholder="Category"
                      onChange={(event) => handleSelectedCategory(event.target.value)}
                    >
                      <option value="" />
                      {auditCategories?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFSelect name="subCategory" label={<span>SubCategory<span style={{ color: 'red' }}> *</span></span>} placeholder="SubCategory">
                      <option value="" />
                      {auditSubCategories?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFSelect name="branch" label={<span>Branch<span style={{ color: 'red' }}> *</span></span>} placeholder="Branch">
                      <option value="" />
                      {auditBranches?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFSelect name="condition" label={<span>Condition<span style={{ color: 'red' }}> *</span></span>} placeholder="Condition">
                      <option value="" />
                      {ASSET_CONDITIONS?.map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </RHFSelect>
                    {/* <TextField name="asset_photo" type='file'/> */}
                  </Box>

                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {!isEdit ? 'Create Asset' : 'Save Changes'}
                    </LoadingButton>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Container>
    </Page>
  );
}
