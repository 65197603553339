// @mui
import { Box, TextField, Button, Stack } from '@mui/material';
import { sentenceCase } from 'change-case';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fDateTime } from '../../../utils/formatTime';
import { getMaintenanceRecord } from '../store/maintenanceActions';
import { fCurrency } from '../../../utils/formatNumber';
import { getProfile } from '../../../shared/auth/authService';
import { AcceptReturn, DeclineReturn } from '.';

MaintenanceDetailCard.propTypes = {
  recordId: PropTypes.string,
};

export default function MaintenanceDetailCard({ recordId }) {
  const dispatch = useDispatch();
  const { maintenanceRecord } = useSelector((state) => state.maintenanceReducer);
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      dispatch(getMaintenanceRecord(recordId));
    }, 2000); // 2000 mil
  };
  const handleDecline = () => {
    setOpenDecline(!openDecline);
    setTimeout(() => {
      dispatch(getMaintenanceRecord(recordId));
    }, 2000); // 2000 mil
  };
  useEffect(() => {
    dispatch(getMaintenanceRecord(recordId));
  }, [dispatch, recordId]);

  useEffect(() => {
    const profile = getProfile();
    if (profile) {
      setUser(profile);
    }
  }, []);
  return (
    <>
      {open && <AcceptReturn open={open} handleClose={handleClose} maintenanceId={recordId} />}
      {openDecline && <DeclineReturn open={openDecline} handleClose={handleDecline} maintenanceId={recordId} />}
      {(maintenanceRecord && (
        <>
          <Stack spacing={1}>
            <Box sx={{ p: 2 }}>
              <Stack spacing={1} direction="row" sx={{ float: 'right' }}>
                {(user?.role === 'MANAGER' || user?.role === 'ADMIN') &&
                  maintenanceRecord?.status === 'AWAITING_MANAGER_ACCEPTANCE' && (
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        size="small"
                        color="error"
                        variant="contained"
                        onClick={() => {
                          setOpenDecline(true);
                        }}
                      >
                        Reject
                      </Button>
                    </>
                  )}
              </Stack>
            </Box>
            <Box
              sx={{
                display: 'grid',
                p: 3,
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <TextField name="asset" label="Asset" value={maintenanceRecord?.asset?.name} disabled />
              <TextField name="branch" label="Branch" value={maintenanceRecord?.asset?.branch?.name} disabled />
              <TextField
                name="manager"
                label="Issued By"
                value={maintenanceRecord?.staff?.user?.display_name}
                disabled
              />
              <TextField
                name="serviceProvider"
                label="Service Provider"
                value={maintenanceRecord?.service_provider?.display_name}
                disabled
              />
              <TextField
                name="serviceProvider"
                label="Service Provider Contact"
                value={maintenanceRecord?.service_provider?.mobile_number}
                disabled
              />
              <TextField
                name="jobCardNumber"
                label="Job Card Number"
                value={maintenanceRecord?.job_card_number}
                disabled
              />
              <TextField
                name="reapirType"
                label="Repair Type"
                value={maintenanceRecord?.repair_type && sentenceCase(maintenanceRecord?.repair_type)}
                disabled
              />
              <TextField name="date" label="Repair Date" value={fDateTime(maintenanceRecord?.created_at)} disabled />
              <TextField
                name="repairStatus"
                label="Repair Status"
                value={maintenanceRecord?.status && sentenceCase(maintenanceRecord?.status)}
                disabled
              />
              <TextField
                name="returnStatus"
                label="Return Status"
                value={maintenanceRecord?.status && sentenceCase(maintenanceRecord?.status)}
                disabled
              />
              <TextField name="duration" label="Duration(Days)" value={maintenanceRecord?.duration} disabled />
              <TextField name="description" label="Description" value={maintenanceRecord?.description} disabled />
              <TextField
                name="estimatedCost"
                label="Estimated Cost"
                value={fCurrency(maintenanceRecord?.estimated_cost)}
                disabled
              />
              <TextField
                name="totalCost"
                label="Total Cost"
                value={fCurrency(maintenanceRecord?.total_cost)}
                disabled
              />
            </Box>
          </Stack>
        </>
      )) ||
        'Maintenance record found'}
    </>
  );
}
